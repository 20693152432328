import { Stack, Button, Typography, Grid, Box, Container } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import buttonTheme from "../misc/ButtonTheme";

import { UnderlineMobile } from "../../styles/hero/heroStylesMobile";

const HeroMobile = () => {
  return (
    <Container
      sx={{
        backgroundImage: `url(${"https://i.ibb.co/QXtqsnW/hero-Background.jpg"})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        height: "auto",
        paddingBottom: "7%",
      }}
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <Typography
            variant="h4"
            textTransform="uppercase"
            fontFamily="Vendome,Times,Times Roman,Times New Roman,serif"
            marginTop="5%"
          >
            About Your Game
          </Typography>
        </Grid>
        <Grid item>
          <UnderlineMobile src="./images/banner/underline.png" />
        </Grid>

        <Grid item>
          <Box
            justifyContent="center"
            sx={{
              width: "90%",
              margin: "auto",
              background: "rgba(0,0,0,.75)",
              color: "#b5b5b5",
              opacity: "0.95",
              marginBottom: "20px",
            }}
          >
            <Stack direction="column">
              <Typography margin="20px">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Enim
                praesent elementum facilisis leo vel fringilla est ullamcorper.
                Elit sed vulputate mi sit amet mauris commodo
              </Typography>
              <ThemeProvider theme={buttonTheme}>
                <Button
                  variant="outlined"
                  href="https://store.steampowered.com/"
                  target="_blank"
                  sx={{
                    color: "#ffffff",
                    height: "40px",
                    margin: "0 20px 20px 20px",
                  }}
                >
                  PLAY NOW
                </Button>
              </ThemeProvider>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default HeroMobile;
