import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import FooterDesktop from "./FooterDesktop";
import FooterMobile from "./FooterMobile";

const Footer = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return <>{matches ? <FooterMobile /> : <FooterDesktop />}</>;
};

export default Footer;
