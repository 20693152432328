import { Container } from "@mui/material";

const Faq = () => {
  return (
    <Container
      maxWidth="100%"
      sx={{
        height: "auto",
        backgroundColor: "#272727",
        backgroundRepeat: "no-repeat;",
        backgroundPosition: "center",
        backgroundSize: "cover",
        padding: "20px",
      }}
    >
      <Container
        sx={{
          backgroundColor: "#1F1F1F",
          padding: "30px",
          borderRadius: "50px",
        }}
      >
        <h2>How I Design Promotional Websites and Why</h2>
        <p>
          I design websites with loading speed, aesthetics and adaptability in
          mind. I want every website I make and manage to look modern to reflect
          well on the product. Have a fast loading speed to retain user
          attention and score high on the Search Engines and be adaptable to
          look good on any device and screen size. I don't focus on features
          such as a database to retain user information because the drawbacks
          are larger than the benefits for a promotional website. I do this with
          the use of React to create Single Page Applications (SPA's) which
          function more like an application even though it's a website!
        </p>
        <br />
        <h2>What You Get When Ordering A Website From Me</h2>
        <p>
          My aim is provide a beautiful looking website that provides a
          call-to-action for users to immediately download the game and engage
          with your brand on social media platforms through strategic placement
          of links on the page.
        </p>
        <br />
        <h2>Advantages of Single Page Applications</h2>
        <p>
          SPAs are on the rise because they provide such an amazing and fast
          user experience.
        </p>
        <p>
          The ability to provide new content seamlessly based on user actions,
          such as button clicks or field-level submissions, is what makes
          single-page applications stand out from their counterparts. Instead of
          refreshing an entire page of content, the application updates or
          alters components based on the user’s action and needs, making it
          quick to respond and easy to interact with in real time.
        </p>
        <p>
          You can see this in action now by clicking on the 'Home' or 'Pricing'
          links and seeing how it quickly it renders the new page!
        </p>
        <br />
        <h2>Why Avoid Databases For A Promotional Website?</h2>
        <p>
          Previously it was common for promotional sites for games to have their
          own forums, and therefore needed for databases to keep track of users
          and their posts. However, these days promotional websites have moved
          away from the use of forums for community engagement and switched to
          social media such as Discord, Reddit, Instagram and TikTok for
          community engagement. This has benefits for websites as well by
          increasing the speed and thereby raising the value it has on the
          Google Search Engine scoring system.
        </p>
      </Container>
    </Container>
  );
};

export default Faq;
