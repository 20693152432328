import { IconButton, Toolbar, AppBar, Box, Grid } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";

const AppbarMobile = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="static">
      <Toolbar variant="dense">
        <Grid container>
          <Grid xs={11} md={8}>
            <Box
              align="left"
              component="img"
              sx={{
                width: "100px",
                height: "70px",
                marginLeft: "5px",
              }}
              alt=""
              src="./images/appbar/GameLogo.png"
            />
          </Grid>
          <Grid xs={1} md={4}>
            <IconButton
              sx={{
                width: "50px",
                height: "50px",
                marginTop: "5px",
                paddingRight: "25px",
              }}
            >
              <MenuIcon
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              ></MenuIcon>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <Link style={{ textDecoration: "none" }} to="/">
                  <MenuItem onClick={handleClose}>Home</MenuItem>
                </Link>
                <Link style={{ textDecoration: "none" }} to="/how">
                  <MenuItem onClick={handleClose}>How it works</MenuItem>
                </Link>
                <Link style={{ textDecoration: "none" }} to="/pricing">
                  <MenuItem onClick={handleClose}>Pricing</MenuItem>
                </Link>
              </Menu>
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default AppbarMobile;
