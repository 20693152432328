import { Typography, Grid, Container } from "@mui/material";
import { MediaImage } from "../../styles/media/mediaStyles";
import { UnderlineMobile } from "../../styles/hero/heroStylesMobile";

const MediaDesktop = () => {
  return (
    <Container
      maxWidth="100%"
      sx={{
        height: "auto",
        paddingBottom: "7%",
        boxShadow: "inset 0px 0px 20px 20px #272727",
        backgroundImage: `url(${"https://i.ibb.co/hCyq5Jx/black-Grid.jpg"})`,
        backgroundRepeat: "no-repeat;",
        backgroundPosition: "center",
      }}
    >
      <Grid
        container
        paddingTop="50px"
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <Typography
            variant="h4"
            textTransform="uppercase"
            fontFamily="Vendome,Times,Times Roman,Times New Roman,serif"
            marginTop="25%"
          >
            Media
          </Typography>
        </Grid>
        <Grid item>
          <UnderlineMobile src="./images/banner/underline.png" />
        </Grid>
      </Grid>
      <Container>
        <Grid
          container
          justifyContent="center"
          paddingTop="50px"
          direction="row"
          spacing={8}
        >
          <Grid item xs={"auto"}>
            <MediaImage
              src="https://i.ibb.co/pzZ3X0J/pixel-art-7284052-640.png"
              style={{ objectFit: "cover" }}
            ></MediaImage>
          </Grid>
          <Grid item xs={"auto"}>
            <MediaImage
              src="https://i.ibb.co/xfFhGGz/pexels-rdne-stock-project-7915357.jpg"
              style={{ objectFit: "cover" }}
            ></MediaImage>
          </Grid>
          <Grid item xs={"auto"}>
            <MediaImage
              src="https://i.ibb.co/1bkR6wH/pexels-cottonbro-studio-4009604.jpg"
              style={{ objectFit: "cover" }}
            ></MediaImage>
          </Grid>
          <Grid item xs={"auto"}>
            <MediaImage
              src="https://i.ibb.co/Yk96vHt/pexels-beata-dudov-228963.jpg"
              style={{ objectFit: "cover" }}
            ></MediaImage>
          </Grid>
          <Grid item xs={"auto"}>
            <MediaImage
              src="https://i.ibb.co/7GWG55Q/pexels-alexander-kovalev-3977908.jpg"
              style={{ objectFit: "cover" }}
            ></MediaImage>
          </Grid>
          <Grid item xs={"auto"}>
            <MediaImage
              src="https://i.ibb.co/QJmwKVH/pexels-francesco-ungaro-1670977.jpg"
              style={{ objectFit: "cover" }}
            ></MediaImage>
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
};

export default MediaDesktop;
