import { Stack, Button, Toolbar, AppBar, Box, Grid } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import buttonTheme from "../misc/ButtonTheme";
import { Link } from "react-router-dom";

const AppbarDesktop = () => {
  return (
    <AppBar position="static">
      <Toolbar variant="dense">
        <Grid container>
          <Grid xs={6} md={8}>
            <Box
              align="left"
              component="img"
              sx={{
                width: "160px",
                height: "40px",
                paddingRight: "20px",
              }}
              alt=""
              src="./images/appbar/GameLogo.png"
            />
            <Stack direction="row">
              <Link to="/">
                <Button color="inherit">Home</Button>
              </Link>
              <Link to="/how">
                <Button color="inherit">How It Works</Button>
              </Link>
              <Link to="/pricing">
                <Button color="inherit">Pricing</Button>
              </Link>
            </Stack>
          </Grid>
          <Grid xs={6} md={4}>
            <Stack alignItems="flex-end">
              <ThemeProvider theme={buttonTheme}>
                <Button
                  variant="outlined"
                  href="https://store.steampowered.com/"
                  target="_blank"
                  sx={{ color: "#ffffff" }}
                >
                  PLAY NOW
                </Button>
              </ThemeProvider>
            </Stack>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default AppbarDesktop;
