import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import MediaDesktop from "./MediaDesktop";
import MediaMobile from "./MediaMobile";

const Media = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return <>{matches ? <MediaMobile /> : <MediaDesktop />}</>;
};

export default Media;
