import { Container } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PricingMobile from "./PricingMobile";
import PricingDesktop from "./PricingDesktop";
const PricingDetails = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return <>{matches ? <PricingMobile /> : <PricingDesktop />}</>;
};

export default PricingDetails;
