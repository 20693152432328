import { Stack, Button, Toolbar, AppBar, Grid } from "@mui/material";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";

const FooterDesktop = () => {
  return (
    <AppBar position="static">
      <Toolbar variant="dense">
        <Grid container justifyContent="center">
          <Grid>
            <Stack direction="row">
              <Button
                variant="link"
                target="_blank"
                href="https://twitter.com"
                color="inherit"
              >
                <TwitterIcon />
              </Button>
              <Button
                variant="link"
                target="_blank"
                href="https://www.facebook.com/"
                color="inherit"
              >
                <FacebookIcon />
              </Button>
              <Button
                variant="link"
                target="_blank"
                href="https://www.youtube.com/"
                color="inherit"
              >
                <YouTubeIcon />
              </Button>
              <Button
                variant="link"
                target="_blank"
                href="https://www.instagram.com/"
                color="inherit"
              >
                <InstagramIcon />
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default FooterDesktop;
