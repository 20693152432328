import React from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";

const PricingDesktop = () => {
  return (
    <Container
      maxWidth="auto"
      sx={{
        height: "auto",
        backgroundColor: "#272727",
        backgroundRepeat: "no-repeat;",
        backgroundPosition: "center",
        backgroundSize: "cover",
        padding: "20px",
      }}
    >
      <Container
        sx={{
          height: "auto",
          minHeight: "90vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid container spacing={2} sx={{ justifyContent: "center" }}>
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              px: 2,
            }}
          >
            {" "}
            {/* Updated alignItems to flex-start */}
            <Paper sx={{ p: 10, borderRadius: "50px" }}>
              <Typography variant="h5">Website as a Product</Typography>
              <Typography variant="h6">$250 once off fee</Typography>
              <Container>
                <ul
                  style={{
                    listStylePosition: "inside",
                    textAlign: "left",
                    paddingLeft: 0,
                    marginBottom: "20px",
                  }}
                >
                  <li>Website built from the scratch</li>
                  <li>
                    React Based SPA technology for incredibly fast loading
                  </li>
                </ul>
                {/* <Button
                variant="contained"
                color="primary"
                style={{ marginTop: "20px" }}
              >
                Contact me
              </Button> */}
              </Container>
            </Paper>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              px: 2,
            }}
          >
            {" "}
            {/* Updated alignItems to flex-start */}
            <Paper sx={{ p: 10, borderRadius: "50px" }}>
              <Typography variant="h5">Website as a Service</Typography>
              <Typography variant="h6">$899/ year</Typography>
              <Container>
                <ul
                  style={{
                    listStylePosition: "inside",
                    textAlign: "left",
                    paddingLeft: 0,
                    marginBottom: "20px",
                  }}
                >
                  <li>Free website built from the ground up</li>
                  <li>Hosting price included</li>
                  <li>Domain name and rental price included</li>
                  <li>Support for any issues that arise</li>
                  <li>Regular updates and optimizations to code</li>
                </ul>
                {/* <Button
                variant="contained"
                color="primary"
                style={{ marginTop: "20px" }}
              >
                Contact me
              </Button> */}
              </Container>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
};

export default PricingDesktop;
