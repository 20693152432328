import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Appbar from "../components/appbar/Appbar";
import Footer from "../components/footer/Footer";
import PricingDetails from "../components/pricing/PricingDetails";
import { useEffect } from "react";
import darkTheme from "../styles/theme/darkTheme";

function Pricing() {
  useEffect(() => {
    document.title = "Market Your Game";
  }, []);

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <div style={{ textAlign: "center" }}>
        <Appbar />
        <PricingDetails />
        <Footer />
      </div>
    </ThemeProvider>
  );
}

export default Pricing;
