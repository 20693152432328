import { Stack, Button, Typography, Grid, Box, Container } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import buttonTheme from "../misc/ButtonTheme";

import { UnderlineMobile } from "../../styles/hero/heroStylesMobile";

const HeroDesktop = () => {
  return (
    <Container
      maxWidth="1920px"
      sx={{
        height: "620px",
        boxShadow: "inset 0px 0px 20px 20px #272727",
        backgroundImage: `url(${"https://i.ibb.co/QXtqsnW/hero-Background.jpg"})`,
        backgroundRepeat: "no-repeat;",
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <Typography
            variant="h4"
            textTransform="uppercase"
            fontFamily="Vendome,Times,Times Roman,Times New Roman,serif"
            marginTop="25%"
          >
            About Your Game
          </Typography>
        </Grid>
        <Grid item>
          <UnderlineMobile src="./images/banner/underline.png" />
        </Grid>

        <Grid item>
          <Box
            sx={{
              width: "40%",
              height: "auto",
              margin: "auto",
              marginTop: "3%",
              background: "rgba(0,0,0,.75)",
              color: "#b5b5b5",
              opacity: "0.95",
              marginBottom: "20px",
              minWidth: "520px",
            }}
          >
            <Stack minWidth="520px" direction="column">
              <Typography margin="30px 120px 30px 120px">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Enim
                praesent elementum facilisis leo vel fringilla est ullamcorper.
                Elit sed vulputate mi sit amet mauris commodo
              </Typography>
              <ThemeProvider theme={buttonTheme}>
                <Button
                  variant="outlined"
                  href="https://store.steampowered.com/"
                  target="_blank"
                  sx={{
                    left: "40%",
                    color: "#ffffff",
                    height: "40px",
                    margin: "0 20px 20px 0px",
                    maxWidth: "120px",
                  }}
                >
                  PLAY NOW
                </Button>
              </ThemeProvider>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default HeroDesktop;
