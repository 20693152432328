import { createTheme } from "@mui/material/styles";

const buttonTheme = createTheme({
  palette: {
    primary: {
      main: "#bfa251",
      contrastText: "#fffff", //button text white instead of black
    },
  },
});

export default buttonTheme;
