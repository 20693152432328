import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Appbar from "../components/appbar/Appbar";
import Hero from "../components/hero/Hero";
import Media from "../components/media/Media";
import Footer from "../components/footer/Footer";
import { useEffect } from "react";
import darkTheme from "../styles/theme/darkTheme";

function Home() {
  useEffect(() => {
    document.title = "Market Your Game";
  }, []);

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <div style={{ textAlign: "center" }}>
        <Appbar />
        <Hero />
        <Media />
        <Footer />
      </div>
    </ThemeProvider>
  );
}

export default Home;
