import { createTheme } from "@mui/material/styles";

export const Colors = {
  primary: "",
  secondary: "",
  success: "",
  info: "",
  danger: "",
  warning: "",
  dark: "",
  light: "",
  muted: "",
  border: "",
  inverse: "",
  shaft: "",
  dim_grey: "",
  dove_grey: "",
  body_bg: "",
  light_grey: "",
  white: "#fff",
  black: "#000",
};

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

// const customTheme = createTheme({
//   pallete: {
//     primary: {
//       main: Colors.primary,
//     },
//     secondary: {
//       main: Colors.secondary,
//     },
//   },

//   components: {
//     MuiButton: {
//       defaultProps: {
//         disableRipple: true,
//         disableElevation: true,
//       },
//     },
//   },
// });

export default darkTheme;
